import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import { ucFirst } from '../../utils/caseconverters';

import styles from './Tag.module.scss';

const Tag = ({ tag, color, modifiers }) => {
    return (
        <span className={className(
            styles['Tag'],
            styles['Tag--' + ucFirst(color)],
            modifiers.map(
                (modifier) => styles['Tag--' + ucFirst(modifier)]
            )
        )}>
            {tag}
        </span>
    );
};

Tag.propTypes = {
    tag: PropTypes.string,
    color: PropTypes.oneOf(['green', 'black', 'red']),
    modifiers: PropTypes.array,
};

Tag.defaultProps = {
    tag: '',
    color: '',
    modifiers: [],
};

export default Tag;
