import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import classNames from 'classnames';

import styles from './ArtistCarousel.module.scss';

import { Navigation } from 'swiper';

import Button from '../Button/Button';
import Tag from '../Tag/Tag';
import Icon from '../Icon';
import Image from '../Image';

const ArtistCarousel = ({ title, artists, link }) => {
    const [swiper, setSwiper] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    if (!artists) {
        return;
    }

    const isMultipleArtists = artists.length > 1;

    const handleNextSwiper = () => {
        if (swiper) {
            swiper.slideNext();
        }
    };

    const handlePrevSwiper = () => {
        if (swiper) {
            swiper.slidePrev();
        }
    };

    const swiperSettings = {
        spaceBetween: 1, // Some kind of overflow work-around
        slidesPerView: 1,
        navigation: {
            nextEl: '.' + styles['ArtistCarousel__Button--Next'],
            prevEl: '.' + styles['ArtistCarousel__Button--Prev'],
        },
        modules: {
            navigation: Navigation,
        },
    };

    return (
        <div className={styles['ArtistCarousel']}>
            <div className={styles['ArtistCarousel__Container']}>
                <h2 className={styles['ArtistCarousel__Title']}>{title}</h2>
                {isMultipleArtists && (
                    <div className={styles['ArtistCarousel__SwiperButtons']}>
                        <button
                            type={'chevron'}
                            className={classNames(
                                styles['ArtistCarousel__Button'],
                                styles['ArtistCarousel__Button--Prev'],
                                {
                                    [styles['ArtistCarousel__Button--Disabled']]:
                                        currentIndex === 0,
                                }
                            )}
                            onClick={() => {
                                handlePrevSwiper();
                            }}
                            disabled={currentIndex === 0}>
                            <span className={'sr-only'}>Previous</span>
                            <Icon type={'chevron'} />
                        </button>
                        <button
                            type={'chevron'}
                            className={classNames(
                                styles['ArtistCarousel__Button'],
                                styles['ArtistCarousel__Button--Next'],
                                {
                                    [styles['ArtistCarousel__Button--Disabled']]:
                                        currentIndex === artists.length - 1,
                                }
                            )}
                            onClick={() => {
                                handleNextSwiper();
                            }}
                            disabled={currentIndex === artists.length - 1}>
                            <span className={'sr-only'}>Next</span>
                            <Icon type={'chevron'} />
                        </button>
                    </div>
                )}

                <Swiper
                    {...swiperSettings}
                    modules={{ navigation: Navigation }}
                    onSwiper={setSwiper}
                    onSlideChange={(e) => {
                        setCurrentIndex(e.activeIndex);
                    }}
                >
                    {artists.map((artist, index) => (
                        <SwiperSlide
                            key={index}
                            className={styles['ArtistCarousel__Card']}>
                            <div
                                className={
                                    styles['ArtistCarousel__CardWrapper']
                                }>
                                <div className={styles['ArtistCarousel__CardImage']}>
                                    {artist.image && (
                                        <Image
                                            {...artist.image}
                                            fill={true}
                                        />
                                    )}

                                </div>
                                <div
                                    className={
                                        styles['ArtistCarousel__TextWrapper']
                                    }>
                                    <div
                                        className={
                                            styles[
                                            'ArtistCarousel__CardContent'
                                            ]
                                        }>
                                        <h3
                                            className={
                                                styles[
                                                'ArtistCarousel__CardTitle'
                                                ]
                                            }>
                                            {artist.title}
                                        </h3>
                                        {artist.tags && (
                                            <div className={styles['ArtistCarousel__TagList']}>
                                                {artist.tags.map((tag, index) => (
                                                    <div key={index} className={styles['ArtistCarousel__Tag']}>
                                                        <Tag
                                                            key={index}
                                                            tag={tag.tag}
                                                            color={tag.color}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        <p
                                            className={
                                                styles[
                                                'ArtistCarousel__CardText'
                                                ]
                                            }>
                                            {artist.preamble}
                                        </p>
                                    </div>
                                    <div
                                        className={
                                            styles[
                                            'ArtistCarousel__ButtonWrapper'
                                            ]
                                        }>
                                        {artist.link && (
                                            <Button
                                                link={artist.link}
                                                text={`Read more about ${artist.title}`}
                                            />
                                        )}

                                        {link && (
                                            <Button type="secondary" link={link} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

ArtistCarousel.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.shape({
                url: PropTypes.string.isRequired,
                alt: PropTypes.string.isRequired,
            }),
            artist: PropTypes.shape({
                name: PropTypes.string.isRequired,
                text: PropTypes.string.isRequired,
            }),
        })
    ),
};

ArtistCarousel.defaultProps = {};

export default ArtistCarousel;
